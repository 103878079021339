import { useContext } from 'react'
import 'style.scss'
import Routes from 'components/AppRoutes'
import { GlobalContext } from 'contexts/globalContext'
import { OrderEngineProvider } from "okeoke.client"
import LoadingScreen from 'components/LoadingScreen'
import packageJson from '../package.json'

function App() {

  const { brandID } = useContext(GlobalContext)
  
  return (
    <>
    <LoadingScreen loading={brandID == null} />
    {brandID != null && 
      <OrderEngineProvider baseUrl={`https://${brandID}.okeoke.io`} app={'pos'} >
          <main className="main-container">
            <Routes />
            <div className="version">v{packageJson.version}</div>
          </main>
      </OrderEngineProvider>}
    </>
  )
}

export default App
