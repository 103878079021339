import { useState, createContext, useEffect, useRef } from 'react'
import { useSecondary } from 'okeoke.client'
import { useNavigate } from 'react-router-dom'

const GlobalContext = createContext()

const GlobalProvider = (props) => {
  
  const getData = message => {
    console.log(message)
    switch(message?.action) {
      case "navigate": navigate(message?.data || "/")
      break
      case "updateBasketItems": setBasketItems(message?.data)
      break
      case "setBrand": setBrandID(message?.data)
      break
      case "updateTotalPrice": setTotalPrice(message?.data)
      break
      case "updateDiscountPrice": setDiscountPrice(message?.data)
      break
      case "updateServiceFee": setServiceFee(message?.data)
      break
      case "updatePaymentsNeed": setPaymentsNeeded(message?.data)
      break
      case "updateTipAmount": setTipAmount(message?.data)
      break
      case "updateStatus": setStatus(message?.data)
      break
      case "tipChange": setTipChange(message?.data)
      break
      default:
    }
  }

  const navigate = useNavigate()
  const { messageMain } = useSecondary(getData)
  const [basketItems, setBasketItems] = useState(null)
  const [brandID, setBrandID] = useState(null)
  const [totalPrice, setTotalPrice] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [paymentsNeeded, setPaymentsNeeded] = useState(0)
  const [tipAmount, setTipAmount] = useState(0)
  const [tipChange, setTipChange] = useState(true)
  const [status, setStatus] = useState(null)
  const timer = useRef()
  
  useEffect(() => {
    getBrandID()
    return () => { clearTimeout(timer.current) }
    // eslint-disable-next-line
  }, [brandID])

  const getBrandID = () => {
    clearTimeout(timer.current)
    if(brandID == null) {
      timer.current = setTimeout(() => {
        sendMessage("getBrandID")
        getBrandID()
      }, 1000
      )
    }
  }

  const update = () => sendMessage("update", Date.now())

  const sendMessage = (action, data) => {
    messageMain({ action, data })
  }

  let value = {
    sendMessage, 
    update,
    brandID, 
    basketItems, 
    totalPrice, 
    discountPrice,
    serviceFee,
    paymentsNeeded,
    tipAmount,
    tipChange,
    status
  }

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }