import { useEffect, useState, useRef} from 'react'
import { GridLoader } from 'react-spinners'

function LoadingScreen(props) {
    
    const { loading } = props
    const timer = useRef(null)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if(loading) {
            setVisible(true) 
        } else {
            clearTimeout(timer.current)
            setVisible(true)
            timer.current = setTimeout(() => {
                setVisible(false)
            }, 300)
        }
        
        return () => { clearTimeout(timer.current) }
    }, [loading])

    return(
        <div className={`loading-screen ${visible ? "visible" : "fade-out"}`}>
            <GridLoader color={"white"} loading={true} />
        </div>  
    )
}

export default LoadingScreen