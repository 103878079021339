import { lazy, Suspense } from "react"
import { Route, Routes, Navigate } from 'react-router-dom'
import LoadingScreen from "components/LoadingScreen"

const TippScreenType1 = lazy(() => import('views/tippScreens/type1'))
const TippScreenType2 = lazy(() => import('views/tippScreens/type2'))

function AppRoutes() {

    return(
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                <Route exact path="" element={<Navigate replace to="/tipp-screens/type1" />} />
                <Route path="tipp-screens">
                    <Route exact path="type1" element={<TippScreenType1 />} />
                    <Route exact path="type2" element={<TippScreenType2 />} />
                </Route>
            </Routes>
        </Suspense>
    )
}

export default AppRoutes