import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from "react-router-dom"
import { GlobalProvider } from 'contexts/globalContext'
import { OkeFrameProvider } from "okeoke.client"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <OkeFrameProvider>
      <GlobalProvider>  
        <App />
      </GlobalProvider>  
    </OkeFrameProvider> 
  </BrowserRouter>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

serviceWorkerRegistration.register()